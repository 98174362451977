import React, { useContext } from "react";
import { GlobalDataContext } from "../../context/context";
import { ButtonContent } from "../global/boton/ButtonContent";
import { BiPhone } from "react-icons/bi";
import imgDefault from '../../assets/image/placeholder.png'
function HeroVideo({ urlVideo }) {
    const { rpdata } = useContext(GlobalDataContext);
    return (
        <div>
            <div className="w-full relative content_video flex justify-center">
                <div
                    className="w-full h-full bg-cover bg-center rounded-3xl  md:-ml-8 ml-0 lg:ml-10"
                    style={{ backgroundImage: `url("${urlVideo ? urlVideo : imgDefault}")` }}
                ></div>
                <div className="flex md:flex-row flex-col md:w-4/5 mx-auto absolute bottom-[30px] md:bottom-[100px] lg:bottom-[90px] md:text-start text-center text-white">
                    <div className="w-full text-center p-4">
                        <h1 className="text-[30px] md:text-[3em] lg:text-[4em]">{rpdata?.dbSlogan?.[0].slogan}</h1>
                        <p className="px-2 md:px-[20%]">{rpdata?.dbValues?.[0].description}</p>
                        <ButtonContent btnStyle="three" />
                    </div>
                </div>
            </div>
            <div className=" md:w-[50%] w-[100%] bg-2 text-white md:-mt-16 -mb-12 -mt-10 relative md:py-8 py-2 homi">
                <a href={`tel:+1${rpdata?.dbPrincipal?.phones?.[0].phone}`}>
                    <ul className="flex md:flex-row flex-col justify-around ml-5 md:ml-0">

                        <h5 className="flex items-center" data-aos="zoom-in" data-aos-duration="1500">
                            <BiPhone
                                fontSize={70}
                                className="text-white capitalize"
                            />we attend emergency calls</h5>
                    </ul>
                </a>

            </div>
        </div>
    );
}
export default HeroVideo;